<template>
  <div class="bombs-setup" v-if="parseInt($route.params.playerN)>0">
    <div class="row">
      <div class="col s12">Расстановка мин у игрока {{$route.params.playerN}}</div>
    </div>
    <div class="field flex flex-direction-column justify-center">
      <div class="row">
        <div class="cell cellH"></div>
        <div class="cell cellH" v-for="l in letters" :key="'cell'+0+'-'+l">{{ l }}</div>
      </div>
      <div class="row" v-for="(row,y) in hub.players[$route.params.playerN-1].field" :key="'row'+y">
        <div class="cell cellH">{{ y + 1 }}</div>
        <div class="cell" :class="{ship:hub.players[$route.params.playerN-1].field[y][x]==='ship',bomb:hub.players[$route.params.playerN-1].field[y][x]==='bomb'}"
             v-for="(exist,x) in row" :key="'cell'+x+'-'+y" @click="triggerBomb(x,y)"></div>
      </div>
    </div>
    <div class="row" style="margin:20px;">
      <div class="col s3"></div>
      <a class="col s6 waves-effect waves-light btn  blue lighten-1" @click="save">Сохранить</a>
      <div class="col s3"></div>
    </div>
    <div class="row" style="margin:20px;">
      <div class="col s3"></div>
      <router-link :to="{name:'gamePreset'}" class="col s6 waves-effect waves-light btn cyan">Главная</router-link>
      <div class="col s3"></div>
    </div>
  </div>
</template>

<script>
import eventHub from "@/eventHub";

export default {
  name: "bombsSetup",
  computed: {
//    hub (){return eventHub},
  },
  data() {
    return {
      dim: 10,//hub.players[this.$route.params.playerN-1].field.length,
      hub: eventHub,
      letters: ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'К',],
    };
  },
  methods: {
    triggerBomb(x, y) {
      if (this.hub.players[this.$route.params.playerN - 1].field[y][x] === 'bomb') {
        eventHub.$set(this.hub.players[this.$route.params.playerN - 1].field[y], x, '')
      } else if (!this.hub.players[this.$route.params.playerN - 1].field[y][x])
        eventHub.$set(this.hub.players[this.$route.params.playerN - 1].field[y], x, 'bomb');
    },
    save() {
      let bc=0;
      for (let y = 0; y < this.dim; y++) {
        for (let x = 0; x < this.dim; x++) {
          if (this.hub.players[this.$route.params.playerN - 1].field[y][x] === 'bomb') {
            bc++;
          }
        }
      }
      if (bc>14){
        alert('Слишком много мин, поведение будет непредсказуемым!')
        bc=14;
      }
      let r=[];
      for (let i=0;i<bc;i++) r.push(Math.random());
      let s=r.slice();
      s.sort();
      this.hub.players[this.$route.params.playerN - 1].bombs=[];
      for (let i=0;i<bc;i++) this.hub.players[this.$route.params.playerN - 1].bombs.push(r.indexOf(s.shift()));
      this.hub.$emit('updateGameCode');
    }
  },
  // mounted() {
  //   console.log(this.hub.players);
  // }
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  margin-bottom: 0;
}

</style>